import React from 'react'


export const Parallaxa= () => {



  return (
     
     <div className="parallax">
          {/* <Parallax  strength={600} bgImage={di} >
             
          </Parallax> */}
        </div>
    
  )
}
